import React, { useState, useEffect } from "react";

import fetchJsonp from "fetch-jsonp";

import Layout from "@accrosoft-ltd/vf-careers-site-theme/src/components/Layout";

import { useI18nContext } from "@accrosoft-ltd/vf-careers-site-theme/src/i18n/i18n";

import { useCookies } from "react-cookie";
import {
  trackAnalyticsEvent,
  trackAnalyticsPageView,
  useStateWithSessionStorage,
} from "@accrosoft-ltd/vf-careers-site-theme/src/components/Analytics";
import { useTracking } from "react-tracking";

require("es6-promise").polyfill();

const JobAlertsPage = ({ pageContext: { config }, location, smartfeedID }) => {
  const { t } = useI18nContext();

  const siteConfig = config;

  const apiKey = smartfeedID || siteConfig.apiKey || siteConfig.smartfeedID;
  const gdprKey =
    smartfeedID ||
    siteConfig.gdprKey ||
    siteConfig.apiKey ||
    siteConfig.smartfeedID;
  const groupOrIdParam = siteConfig.group ? "group" : "id";
  const [smartfeedCompanyData, setSmartfeedCompanyData] = useState({});
  const [appGatewayURL, setAppGatewayURL] = useState({});
  const [vacanciesRouteLoading, setVacanciesRouteLoading] = useState(true);
  const [vacanciesRouteError, setVacanciesRouteError] = useState(null);
  const [optinID, setOptinID] = useState(null);
  const [optinStatus, setOptinStatus] = useState("initial");

  const [cookies, setCookie] = useCookies(["ap-signals-user-id"]);
  const [sessionIDValue, setSessionIDValue] = useStateWithSessionStorage(
    "ap-signals-session-id"
  );

  const { trackEvent } = useTracking(
    {
      page: "JobAlertsPage_OptIn",
      location: location,
      apiKey: siteConfig.apiKey,
    },
    {
      dispatch: (data) =>
        trackAnalyticsEvent(
          data,
          cookies["ap-signals-user-id"],
          sessionIDValue,
          siteConfig
        ),
    }
  );

  useEffect(() => {
    trackAnalyticsPageView(
      location,
      cookies["ap-signals-user-id"],
      sessionIDValue,
      siteConfig
    );
  }, [cookies, location, sessionIDValue, siteConfig]);

  useEffect(() => {
    if (apiKey) {
      let searchParams = new URLSearchParams(location.search);
      let entries = searchParams.entries();

      for (let pair of entries) {
        if (pair[0] === "OptInID") {
          setOptinID(pair[1]);
        }
      }

      let companyDetailsEndpoint = "GetCompanyDetails";

      if (groupOrIdParam === "group") {
        companyDetailsEndpoint = "GetGroupDetails";
      }

      try {
        fetchJsonp(
          siteConfig.sfAPIEndpoint +
            "/CareerPage/" +
            companyDetailsEndpoint +
            "?id=" +
            apiKey,
          {
            timeout: 15000,
          }
        )
          .then(function (response) {
            return response.json();
          })
          .then(function (jsonList) {
            fetchJsonp(
              siteConfig.sfAPIEndpoint +
                "/CareerPage/" +
                "GetApplicantGetwayURL" +
                "?companyId" +
                "=" +
                gdprKey,
              {
                timeout: 15000,
              }
            )
              .then(function (response) {
                return response.json();
              })
              .then(function (appgatway) {
                setVacanciesRouteLoading(false);
                if (
                  jsonList &&
                  jsonList.CompanyDetails &&
                  jsonList.CompanyDetails.length > 0
                ) {
                  setSmartfeedCompanyData(jsonList.CompanyDetails[0]);
                } else {
                  setSmartfeedCompanyData(jsonList);
                }
                setAppGatewayURL(appgatway);
              })
              .catch(function (ex) {
                setVacanciesRouteLoading(false);
                setVacanciesRouteError(ex);
                console.error(ex, "error");
              });
          })
          .catch(function (ex) {
            setVacanciesRouteLoading(false);
            setVacanciesRouteError(ex);
            console.error(ex, "error");
          });
      } catch (ex) {
        setVacanciesRouteLoading(false);
        setVacanciesRouteError(ex);
        console.error(ex, "error");
      }
    } else {
      setVacanciesRouteLoading(false);
      setVacanciesRouteError("No API key was provided.");
    }
  }, [
    apiKey,
    gdprKey,
    location.search,
    siteConfig.sfAPIEndpoint,
    groupOrIdParam,
  ]);

  function optIntoJobAlerts(optinID) {
    var dataObjOptIn = {
      JobAlertId: encodeURIComponent(optinID),
    };

    const dataObjQueryString = Object.keys(dataObjOptIn)
      .map((key) => key + "=" + dataObjOptIn[key])
      .join("&");

    fetchJsonp(
      siteConfig.sfAPIEndpoint +
        "/CareerPage/" +
        "OptInToJobAlerts?" +
        dataObjQueryString,
      {
        timeout: 15000,
      }
    )
      .then(function (response) {
        return response.json();
      })
      .then(function (jsonList) {
        if (jsonList !== "Error") {
          trackEvent({
            eventCategory: "JobAlertsPage",
            eventAction: "optedIn",
          });
          setVacanciesRouteLoading(false);
          setOptinStatus("Success");
        } else {
          setVacanciesRouteLoading(false);
          setOptinStatus("Error");
        }
      })
      .catch(function (ex) {
        setOptinStatus("Error");
        setVacanciesRouteLoading(false);
        setVacanciesRouteError(ex);
        console.error(ex, "error");
      });
  }

  return (
    <Layout
      location={location}
      apiKey={apiKey}
      jobAlertsPage={true}
      groupOrIdParam={groupOrIdParam}
      smartfeedCompanyData={smartfeedCompanyData}
      appGatewayURL={appGatewayURL}
      vacanciesRouteLoading={vacanciesRouteLoading}
      vacanciesRouteError={vacanciesRouteError}
      title="Opt in for Job Alerts"
    >
      {!smartfeedCompanyData ? (
        <>
          <div className="page__header-container page__header-container--job-alerts-landing">
            <div className="container">
              <div
                className="row justify-content-center page__header page__header--landing job-alerts-landing__header page__header--job-alerts-landing"
                id="listing-top"
              >
                <div className="col-12 col-lg-8 job-alerts-title-wrap">
                  <p>{t("job-alerts.messages.please-wait-config")}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : smartfeedCompanyData &&
        !vacanciesRouteLoading &&
        !vacanciesRouteError ? (
        <>
          <div className="page__header-container page__header-container--job-alerts-landing">
            <div className="container">
              <div
                className="row justify-content-center page__header page__header--landing job-alerts-landing__header page__header--job-alerts-landing"
                id="listing-top"
              >
                <div className="col-12 col-lg-8 job-alerts-title-wrap">
                  {optinID ? (
                    <>
                      <h1>{t("job-alerts.optin.title")}</h1>
                      <p>{t("job-alerts.optin.subtitle")}</p>
                    </>
                  ) : (
                    <h1>{t("job-alerts.optin.id-required")}</h1>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="jobalerts-landing">
            <div className="container">
              <div className="row clearfix">
                <div className="col-md-12 column">
                  <div className="smartfeed-spacing">
                    {smartfeedCompanyData.JobAlertCompanySettings && optinID ? (
                      <div className="col-12 col-lg-8 job-alerts-panel opt-in-panel">
                        {optinStatus === "initial" ? (
                          <button
                            onClick={() => optIntoJobAlerts(optinID)}
                            className="nav-action-sm"
                          >
                            {t("job-alerts.optin.optin-to-job-alerts")}
                          </button>
                        ) : optinStatus === "Success" ? (
                          <strong>{t("job-alerts.optin.success")}</strong>
                        ) : optinStatus === "Error" ? (
                          <strong>{t("job-alerts.optin.unknown-error")}</strong>
                        ) : (
                          <strong>
                            {t("job-alerts.optin.unknown-status")}
                          </strong>
                        )}
                      </div>
                    ) : (
                      <p>{t("job-alerts.optin.could-not-load")}</p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="page__header-container page__header-container--job-alerts-landing">
            <div className="container">
              <div
                className="row justify-content-center page__header page__header--landing job-alerts-landing__header page__header--job-alerts-landing"
                id="listing-top"
              >
                <div className="col-12 col-lg-8 job-alerts-title-wrap">
                  <p>{t("job-alerts.optin.please-wait")}</p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Layout>
  );
};

export default JobAlertsPage;
